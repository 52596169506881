import React from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Form,
  Transition,
} from "semantic-ui-react";
import image from "./images/casual.jpg";
import VizSensor from "react-visibility-sensor";
import Contact from "./Contact";
const skills = ["PyTorch", "TensorFlow", "Next.js", "AWS ML", "AWS Serverless"];

class About extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://cdn.credly.com/assets/utilities/embed.js";
    script.async = true;

    document.body.appendChild(script);
  }
  render() {
    return (
      <div>
        <Container>
          <Segment style={{ padding: "4em 0em" }} vertical>
            <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column width={13}>
                  <Header as="h3" style={{ fontSize: "2em" }}>
                    Who am I?
                  </Header>
                  <p style={{ fontSize: "1.33em", maxWidth: "35em" }}>
                    I'm an Artificial Intelligence student at CMU and the CEO /
                    Cofounder of <a href="https://sitewiz.ai">Sitewiz</a>. My
                    work experience includes multiple AI and full stack
                    developer positions at startups along with consulting on AWS
                    IQ. I've trained Generative AI Models like VAEs and GANs
                    from scratch.
                  </p>
                  <a
                    href="/qageneration.pdf"
                    style={{
                      color: "blue",
                      fontSize: "1.2em",
                    }}
                  >
                    NLP Paper
                  </a>
                </Grid.Column>
                <Grid.Column width={3}>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "1em",
                      paddingLeft: "1em",
                      height: "270",
                    }}
                  >
                    <div
                      data-iframe-width="150"
                      data-iframe-height="270"
                      data-share-badge-id="20d9eb61-7342-4d42-8b76-42190055269f"
                      data-share-badge-host="https://www.credly.com"
                    ></div>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column>
                  <Image
                    bordered
                    rounded
                    size="large"
                    src={image}
                    alt="Picture of me"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header as="h5" style={{ fontSize: "1.5em" }}>
                    Coding Languages/Proficiencies
                  </Header>
                  <div
                    class="ui segment"
                    style={{ width: "100%", marginTop: "-.5%" }}
                  >
                    <div class="ui relaxed divided list">
                      {skills.map((skill) => (
                        <div class="item">
                          <div class="content">{skill}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <iframe
                  style={{ width: "825px", height: "600px" }}
                  src="https://docs.google.com/document/d/e/2PACX-1vTLOnGhpSSA2XwWUigBB3t4k5gcbHSJMUMaaWpBz_l5dJ9odqIrf4m3BbOcc05Rim9ugjRNMMY1az09/pub?embedded=true"
                ></iframe>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default About;
