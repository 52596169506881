export default function(state = [], action) {
    //
    switch (action.type) {
        case "link":
            
            
            return action.payload;
        default: 
            return state;
    }
}