import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import * as actions from "./actions";
import { connect } from "react-redux";

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  return window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;
    let bool1,
      bool2,
      bool3,
      bool4 = false;
    switch (this.props.links) {
      case "/":
        bool1 = true;
        bool2 = false;
        bool3 = false;
        bool4 = false;
        break;
      case "/projects":
        bool1 = false;
        bool2 = true;
        bool3 = false;
        bool4 = false;
        break;
      case "/about":
        bool1 = false;
        bool2 = false;
        bool3 = false;
        bool4 = true;
        break;
      case "/consulting":
        bool1 = false;
        bool2 = false;
        bool3 = true;
        bool4 = false;
        break;
      default:
        bool1 = false;
        bool2 = false;
        bool3 = false;
        bool4 = false;
        break;
    }
    return (
      <Responsive getWidth={getWidth}>
        {this.props.links.length === 0 ? null : (
          <Redirect push to={this.props.links} />
        )}
        <Visibility
          once={false}
          onTopPassed={this.showFixedMenu}
          onTopPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ padding: "1em 0em" }}
            vertical
          >
            <Menu
              fixed={fixed ? "top" : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item
                  as="a"
                  active={bool1}
                  onClick={() => {
                    this.props.link("/");
                  }}
                >
                  Home
                </Menu.Item>
                <Menu.Item
                  as="a"
                  active={bool4}
                  onClick={() => {
                    this.props.link("/about");
                  }}
                >
                  About
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

let NavigationHead = ({ children }) => (
  <DesktopContainer>{children}</DesktopContainer>
);

NavigationHead = withRouter((props) => <DesktopContainer {...props} />);

NavigationHead.propTypes = {
  children: PropTypes.node,
};

function mapStateToProps({ links }) {
  return { links };
}

export default connect(mapStateToProps, actions)(NavigationHead);
