import PropTypes from "prop-types";
import React, { Component } from "react";
import VizSensor from "react-visibility-sensor";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Form,
  Transition,
} from "semantic-ui-react";
import { Redirect, Link } from "react-router-dom";
import Typed from "react-typed";
import * as actions from "./actions";
import { connect } from "react-redux";

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  return window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

const Typer = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        textAlign: "center",
        padding: "30% 0",
        marginLeft: "0%",
      }}
    >
      <h1
        style={{
          fontSize: `${window.innerWidth / 400 + 1}em`,
          fontFamily: "Verdana, Geneva, sans-serif",
        }}
      >
        Ram Potham
      </h1>
      <br />
      <Typed
        loop
        typeSpeed={60}
        backSpeed={60}
        strings={["is an AI Researcher", "is a Founder", "is a Buddhist"]}
        smartBackspace
        shuffle={false}
        backDelay={1000}
        fadeOut={false}
        fadeOutDelay={100}
        loopCount={0}
        showCursor
        cursorChar="|"
        style={{
          fontSize: `${window.innerWidth / 400}em`,
          fontFamily: "Courier New, Courier, monospace",
        }}
      />
    </div>
  );
};
class DevPost extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={80}
        height={80}
        viewBox="0 0 226 226"
        style={{ fill: "#000000" }}
      >
        <g
          fill="none"
          fillRule="nonzero"
          stroke="none"
          strokeWidth={1}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          strokeDasharray
          strokeDashoffset={0}
          fontFamily="none"
          fontWeight="none"
          fontSize="none"
          textAnchor="none"
          style={{ mixBlendMode: "normal" }}
        >
          <path d="M0,226v-226h226v226z" fill="none" />
          <g id="original-icon" fill="#BBB">
            <path d="M56.5,18.83333l-50.61458,94.16667l50.61458,94.16667h113l51.38704,-94.16667l-51.38704,-94.16667zM75.33333,65.91667l48.37077,0.07357c12.58007,1.76563 36.37923,13.79394 36.37923,47.00976c0,33.21582 -23.79916,45.24415 -36.37923,46.97298l-0.66211,0.11035h-47.70866zM94.16667,84.75v56.5h27.44076c3.45768,-0.6989 19.64257,-5.2233 19.64257,-28.25c0,-22.91634 -15.96419,-27.51432 -19.67936,-28.25z" />
          </g>
        </g>
      </svg>
    );
  }
}

class CompanyIcons extends Component {
  state = { visible: false, duration: 3000, width: window.innerWidth };

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.setState((prevState) => ({ visible: !prevState.visible }));
    this.anim();
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  anim() {
    let a = 0;
    const timer = setInterval(() => {
      this.setState((prevState) => ({ visible: !prevState.visible }));
      a++;
      if (a > 3) {
        clearInterval(timer);
      }
    }, 4000);
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    return (
      <div
        style={
          isMobile
            ? {
                position: "absolute",
                bottom: "5%",
                width: "100%",
                marginLeft: "-6%",
              }
            : {
                height: "10em",
                width: "5em",
                position: "absolute",
                bottom: "10em",
                right: "1em",
              }
        }
      >
        <Transition
          animation="pulse"
          duration={this.state.duration}
          visible={this.state.visible}
        >
          <a
            href="https://www.linkedin.com/in/ram-potham-65b0b21b0/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              class="huge link linkedin icon ui"
              style={{ color: "#EEE", marginTop: "0px" }}
            ></i>
          </a>
        </Transition>
        <Transition
          animation="pulse"
          duration={this.state.duration}
          visible={this.state.visible}
        >
          <a
            href="https://github.com/rapturt9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              class="huge link github icon"
              style={
                isMobile
                  ? { color: "#EEE" }
                  : { marginTop: "10px", color: "#EEE" }
              }
            ></i>
          </a>
        </Transition>
      </div>
    ); /*
    return (
      <div
        style={{
          height: "10em",
          width: "5em",
          position: "absolute",
          bottom: "10em",
          right: "1em",
        }}
      >
        <Transition
          animation="pulse"
          duration={this.state.duration}
          visible={this.state.visible}
        >
          <a
            href="https://www.linkedin.com/in/rampotham/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="huge link linkedin icon ui" style={{ color: "#EEE" }}></i>
          </a>
        </Transition>
        <Transition
          animation="pulse"
          duration={this.state.duration}
          visible={this.state.visible}
        >
          <a
            href="https://github.com/rapturt9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              class="huge link github icon"
              style={{ marginTop: "10px", color: "#EEE" }}
            ></i>
          </a>
        </Transition>
        <Transition
          animation="pulse"
          duration={this.state.duration}
          visible={this.state.visible}
        >
          <a
            href="https://www.youtube.com/channel/UCbrtLu8XnXIBMCHT96JkFHg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              class="huge link youtube icon"
              style={{ marginTop: "10px", color: "#EEE" }}
            ></i>
          </a>
        </Transition>
        <a
          href="https://devpost.com/ram-potham?ref_content=user-portfolio&ref_feature=portfolio&ref_medium=global-nav"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DevPost />
        </a>
      </div>
    );*/
  }
}

const HomepageHeading = ({ mobile }) => (
  <Container text style={{ width: window.innerWidth }}>
    <Typer />
    <CompanyIcons />
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = { link: "/" };

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} style={{ overflow: "hidden" }}>
        <Redirect push to={this.props.links} />
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ height: window.innerHeight, padding: "1em 0em" }}
            vertical
          >
            <Menu
              fixed={fixed ? "top" : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item
                  as="a"
                  active
                  onClick={() => {
                    this.props.link("/");
                    this.setState({ link: "/" });
                  }}
                >
                  Home
                </Menu.Item>
                <Menu.Item
                  as="a"
                  onClick={() => {
                    this.props.link("/about");
                    this.setState({ link: "/about" });
                  }}
                >
                  About
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <DesktopContainer>{children}</DesktopContainer>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

class HomepageLayout extends Component {
  state = { link: "/", duration: 1000, imgViz: false, imgViz2: false };

  render() {
    return (
      <ResponsiveContainer>
        <Redirect push to={this.state.link} />
      </ResponsiveContainer>
    );
  }
}

function mapStateToProps({ links }) {
  return { links };
}
DesktopContainer = connect(mapStateToProps, actions)(DesktopContainer);
export default connect(mapStateToProps, actions)(HomepageLayout);

/*        <VizSensor
        onChange={(isVisible) => {
          if(!this.state.imgViz){ 
            this.setState({imgViz: isVisible})
          }
        }}
      >
          <Segment style={{ padding: "4em 0em" }} vertical>
        <Transition
          animation="slide left"
          duration={this.state.duration}
          visible={this.state.imgViz}
        >
        
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
            <Grid.Column floated='left' width={5}>
            <Image bordered circular size='large' src='https://i.ibb.co/D47C2F8/IMG-7048.jpg' alt='Picture of me'/>
          </Grid.Column>
              <Grid.Column width={11} floated='right'>
                <Header as="h3" style={{ fontSize: "2em" }}>
                  A little bit more about me
                </Header>
                <p style={{ fontSize: "1.33em"}}>
                  Just a mad coder trying to hack the world into a better place one project at a time.
                  <br />
                  <br />
                  See my full bio <Link onClick={()=>{
                    this.props.link("/about");
                    this.setState({link:"/about"});
                  }}>here</Link>
                </p>
              </Grid.Column>

            </Grid.Row>

          </Grid>
        
        </Transition>
        </Segment>
        </VizSensor>

<VizSensor
        onChange={(isVisible) => {
          if(!this.state.imgViz2){ 
            this.setState({imgViz2: isVisible})
          }
        }}
      >
          <Segment style={{ padding: "4em 0em" }} vertical>
        <Transition
          animation="slide right"
          duration={this.state.duration}
          visible={this.state.imgViz2}
        >
          <Container text>
          <a href="http://navigation.rampotham.com" target="_blank">
            <Header as="h1" id="myProjects" style={{ fontSize: "2em" ,textAlign:"center", fontFamily:"'Trebuchet MS', Helvetica, sans-serif"}}>
              <b>Check out my projects!</b>
            </Header>
            </a>
          </Container>
        </Transition>
        </Segment>
        </VizSensor>
*/
