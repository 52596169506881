import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Form,
  Transition,
} from "semantic-ui-react";
import * as actions from "./actions";
import { connect } from "react-redux";
import { InlineWidget } from "react-calendly";

class Contact extends React.Component {
  state = { name: "", email: "", subject: "", body: "" };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  /*handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state);
    this.setState({name:"", email:"", subject:"", body:""});
    this.props.send(this.state);
  };*/

  render() {
    return (
      <Segment style={{ padding: "4em 0em" }} vertical>
          <Grid container verticalAlign="middle" columns='equal'>
            <Grid.Row>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Schedule a Meeting
              </Header>
            </Grid.Row>
          </Grid>
          <InlineWidget url="https://calendly.com/ram-potham" scrolling="no" />
      </Segment>
    );
  }
}

function mapStateToProps({ sent }) {
  return { sent };
}

export default connect(mapStateToProps, actions)(Contact);
