const axios = require('axios')

export const link = (str="/") => {
    return {
        type: "link",
        payload: str
    }
}

export const send = (obj = {}) => async dispatch => {
    //email name subject body
    let object={};
    object.email="ram.potham@gmail.com";
    object.name=obj.name;
    object.content=`subject: ${obj.subject}\nemail: ${obj.email}\nbody: ${obj.body}`;
    console.log(object);
    const URL="https://33novyl69c.execute-api.us-east-1.amazonaws.com/dev/email/send";
    const res = await axios.post(URL,object);
    dispatch({ type: "send", payload: res.data });
  };