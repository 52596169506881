import React from "react";
import { Grid, Image, Label, Button } from 'semantic-ui-react'
import "./App.css";
//66-85 https://ibb.co/J7QZJSj
const color={
  IoT:"grey",
  "Open Source":"red",
  "Node.js":"blue",
  "React.js":"orange",
  Hardware:"teal",
  AWS:"purple",
  Alexa:"brown",
  Twilio:"green",
  Javascript:"yellow",
  "Game Engine":"black"
}
const arr = [
  [
    "FarmFight",
    "A 2d multiplayer game where the goal is to take control of the board. Become a farming tycoon and fight your friends!",
    "https://i.ibb.co/VtLF9Rb/Farm-Fight-Icon.png",        
    "https://www.farmfight.io/",
    ["tags","Game Engine"],
    ["github","https://github.com/rapturt9/FarmFight"],
    ["Try it out!","https://www.farmfight.io/"],
  ],
  [
    "News Printer",
    "Press a button to cause a printer prints a sheet with 2 QR codes. By looking at the QR codes with a camera, you see the latest videos from two news sources - NBC and CDC with echoAR.",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/392/042/datas/gallery.jpg",
    "https://devpost.com/software/news-printer",
    ["tags","IoT","Node.js"],
    ["youtube","https://youtu.be/7ngarPJhuf0"],
    ["github","https://github.com/rapturt9/echoar-printer"],
  ],
  [
    "Transfil",
    "Transfer files online securely, fast, and with a very nice UI",
    "https://i.ibb.co/HDktKbD/Screen-Shot-2021-02-21-at-1-13-22-PM.png",        "https://devpost.com/software/transfil-transfer-files-online",
    ["tags","AWS","React.js","Twilio"],
    ["youtube","https://youtu.be/bpG9QIGClEA"],
    ["github","https://github.com/rapturt9/transfil"],
    ["Try it out!","https://transfil.tech"],
  ],
  [
    "Virtual Presence",
    "You can control a moving robot with up, down, left, and right commands. Meanwhile, a phone is mounted on the robot, so you can talk to others through the phone.",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/392/074/datas/gallery.jpg",
    "https://devpost.com/software/virtual-presence",
    ["tags","IoT","React.js"],
    ["youtube","https://youtu.be/Y1O_H17W7Rc"],
    ["github","https://github.com/rapturt9/virtual-presence"],
  ],
  [
    "Web Footprint",
    "A way to track your carbon footprint from your web surfing",
        "https://i.ibb.co/YZ91247/Screen-Shot-2021-11-06-at-12-43-51-PM.png",
        "https://rapturt9.github.io/web_footprint/",
    ["tags","Chrome Extension","React.js"],
    ["youtube","https://www.youtube.com/watch?v=UpthP7HmNZg&t=1s"],
    ["github","https://github.com/rapturt9/web_footprint"],
    ["Try it out!","https://rapturt9.github.io/web_footprint/"],
  ],
  [
    "Quarantine Pickup",
    "It uses a lockbox which can be opened or closed using the internet containing a UV light to drastically reduce the risk of transmission of germs and viruses during restaurant takeout pickup",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/102/379/datas/gallery.jpg",
    "https://devpost.com/software/germ-blocker",
    ["tags","IoT"],
    ["youtube","https://youtu.be/0ZxYe2flZ4Q"],
    ["github","https://github.com/rapturt9/GermBlocker"],
  ],
  [
    "Click Detect",
    "A react library that's easy to install and use to detect bots that spam click",    
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_thumbnail_photos/001/408/569/datas/medium.png",
        "https://devpost.com/software/click-detect",
    ["tags","Open Source","React.js"],
    ["youtube","https://youtu.be/fmmYJOG-z-w"],
    ["github","https://github.com/rapturt9/BotDetection/"],
    ["Try it out!","https://bit.dev/bot/click-detect/click-detect"],
  ],
  [
    "React Tutorial",
    "Tutorial on how to build and deploy a react app",    
    "https://i.ibb.co/wRQBg47/Screen-Shot-2021-06-18-at-1-28-24-PM.png",
        "bit.ly/tutorial-ram",
    ["tags","Open Source","React.js"],
    ["Try it out!","bit.ly/tutorial-ram"],
  ],
  [
    "NutriPick",
    "Find food from locals who are looking to help the community.",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/373/333/datas/gallery.jpg",
    "https://devpost.com/software/nutripick",
    ["tags","Hardware","React.js"],
    ["youtube","https://youtu.be/rIh_0tSxn4s"],
    ["github","https://github.com/rapturt9/foodproject"],
  ],
  [
    "Virtual Remote",
    "Turn all your pesky remotes into virtual ones by recordint IR signals from the remotes on a website and then play them back",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/383/480/datas/gallery.jpg",
    "https://devpost.com/software/virtual-remote",
    ["tags","IoT","React.js"],
    ["youtube","https://youtu.be/CwRYh6z6tYA"],
    ["github","https://github.com/rapturt9/virtualremote"],
    ["Try it out!","https://www.virtualremote.biz/"],
  ],
  [
    "Exercise Together",
    `Exercise Together is a web app that allows 3 people to
        share video while watching the same Youtube exercise
        class and log their exercise activity`,
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/122/646/datas/gallery.jpg",
    "https://devpost.com/software/exercise-together",
    ["tags","AWS","React.js"],
    ["youtube","https://youtu.be/hdQI26z0SI0"],
    ["github","https://github.com/rapturt9/ExerciseTogether"],
    ["Try it out!","https://exercisetogether.rampotham.com"],
  ],
  [
    "Cheap Flights",
    "An Alexa skill that easily enables users to get information about cheap flights to their favorite destinations",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/144/357/datas/gallery.jpg",
    "https://devpost.com/software/cheap-flights",
    ["tags","Alexa"],
    ["youtube","https://youtu.be/cWkNX6dF0tM"],
    ["github","https://github.com/rapturt9/cheap_flights/tree/master"],
  ],
  [
    "WebUSB Arduino React",
    "This allows you to connect a supported Arduino to chrome using WebUSB and use React.js to send and receive messages from Arduino.",   "https://i.ibb.co/GpznNZv/Screen-Shot-2021-02-21-at-1-22-08-PM.png",    "https://github.com/rapturt9/WebUSB-Arduino-React.js",
    ["tags","Open Source","IoT","React.js"],
    ["github","https://github.com/rapturt9/WebUSB-Arduino-React.js"],
    ["Try it out!","https://rapturt9.github.io/WebUSB-Arduino-React.js/"],
  ],
  [
    "Laser",
    "Laser is a fun, online multiplayer game that can be played with any number of people. Simply join the same room name to get started",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/140/372/datas/gallery.jpg",
    "https://devpost.com/software/laser-w6x8vz",
    ["tags","AWS","React.js"],
    ["youtube","https://youtu.be/T9f19Vh6GoQ"],
    ["github","https://github.com/rapturt9/laser"],
    ["Try it out!","http://lasergun.rampotham.com/"],
  ],
  [
    "Simple Tables",
    "A simple way to edit, interact with, and collaborate on DynamoDB tables",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/138/838/datas/gallery.jpg",
    "https://devpost.com/software/easy-tables",
    ["tags","AWS","React.js"],
    ["youtube","https://youtu.be/VtKoHNk-Jcg"],
    ["github","https://github.com/rapturt9/easytables"],
  ],
  [
    "Music Player",
    "An arduino project that allow you to play any song you wing using a piezo alarm and music XML file. Moreover, wireless communication with the Arduino is possible through Blynk",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/115/204/datas/gallery.jpg",
    "https://devpost.com/software/music-player-fjo4z3",
    ["tags","Hardware"],
    ["youtube","https://youtu.be/PBroetXZyLs"],
    ["github","https://github.com/rapturt9/MusicPlayer"],
  ],
  [
    "Undead Run",
    "A zombie is running towards you in google maps. Try to survive as long as you can",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/103/812/datas/original.jpg",
    "https://devpost.com/software/undead-run-b5mrl6",
    ["tags","Javascript"],
    ["youtube","https://youtu.be/HHdjr6iEWIw"],
    ["github","https://github.com/rapturt9/ZombieRun/tree/master"],
    ["Try it out!","https://codepen.io/rapturt9/full/xxwBeaz"],
  ],
  [
    "Coin Finder",
    "Collect as many coins as you can on google maps by running around",
    "https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/101/046/datas/original.jpg",
    "https://devpost.com/software/coin-finder",
    ["tags","Javascript"],
    ["youtube","https://youtu.be/jmwSM1YeG5E"],
    ["github","https://github.com/rapturt9/CoinFinder/tree/master"],
    ["Try it out!","https://codepen.io/rapturt9/full/LYpaNVK"],
  ],
];
console.log(arr.map(item=>item[4]));
class App extends React.Component {
  state={filter:""}
  render() {
    return (
      <>
      <div
        className="ui stackable three column grid"
        style={{ paddingLeft: "1em", paddingRight: "1em", paddingTop: "1em" }}
      >
        {arr.filter((item) => !this.state.filter || item[4].includes(this.state.filter)).map(item=>(
            <div className="column" key={item[0]}>
              <div className="ui card" style={{ width: "100%" }}>
                  <div className="image">
                    <Image src={item[2]} />
                    {item[4].map((tag,i)=>{
                      if(i!==0){
                        return  <Label onClick={()=>this.setState({filter:[tag][0]})} style={{top:`calc(100% - ${i*3}em)`}} as='a' color={color[tag]} ribbon='right'>
                        {tag}
                      </Label>
                      }
                    })}
                  </div>
                  <div className="content">
                    <h1 className="header">{item[0]}</h1>
                    <div className="description hover" ><a target="_blank" className="hover" rel="noopener noreferrer" href={item[3]}>{item[1]}...</a></div>
                  </div>
                <div className="extra content">
                  {item.map(val => {
                      if(Array.isArray(val)&&val[0]!=="tags"){
                          let classes="big icon "+val[0];
                          if(val[0]==="Try it out!"){
                              return <a target="_blank" rel="noopener noreferrer" href={val[1]} key={val[0]}>See demonstration here!</a>;
                          }
                          return <a target="_blank" rel="noopener noreferrer" href={val[1]} key={val[0]}><i class={classes}/></a>
                      }
                  })}
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <br/>
      {this.state.filter?<Button fluid secondary onClick={()=>this.setState({filter:""})}>Reset selection</Button>:null}
      <br/>
      </>
    );
  }
}


export default App;
