import PropTypes from "prop-types";
import React, { Component } from "react";
import VizSensor from "react-visibility-sensor";
import * as actions from "./actions";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Form,
  Transition,
} from "semantic-ui-react";
import { Redirect, withRouter } from "react-router-dom";

class Blog extends Component {
  render() {
    return (
      <div>
        <h1>Coming Soon!</h1>
      </div>
    );
  }
}

function mapStateToProps({ links }) {
  return { links };
}

export default connect(mapStateToProps, actions)(Blog);
