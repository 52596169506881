import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
//import * as actions from '../actions';
import HomepageLayout from "./HomepageLayout";
import NavigationHead from "./NavigationHead";
import About from "./About";
import Blog from "./Blog";
import Projects from "./Projects";
import Consulting from "./Consulting";

class App extends Component {
  componentDidMount() {}
  //<Header />
  render() {
    return (
      <div className="container">
        <div className="buzzbonus"></div>
        <BrowserRouter>
          <Route path="/">
            <div>
              <Switch>
                <Route exact path="/" component={HomepageLayout} />
                <Route path="/" component={NavigationHead} />
              </Switch>
            </div>
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/consulting">
            <Consulting />
          </Route>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
